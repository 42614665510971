$(function() {
  // SP用ハンバーガーメニュー
  $('.js-mobile-menu-trigger').on('click', function() {
    const $header = $('.header');
    if($header.hasClass('menu-open')) {
      $header.removeClass('menu-open');
    } else {
      $header.addClass('menu-open')
    }
  });

  // スムーススクロール
  $('a[href^="#"]').on('click', function() {
    const speed = 400;
    const href = $(this).attr("href");
    const target = $(href == "#" || href == "" ? 'html' : href);
    const position = target.offset().top;
    $("html, body").animate({ scrollTop: position }, speed, "swing");
    return false;
  });

  // タブレット時に記事一覧のホバー挙動制御
  $('.c-card-link').on('click', function(e) {
    const width = $(window).width();
    if(width > 767 && width < 1025) {
      if($(this).hasClass('view-text')) {
        const url = $(this).attr('href');
        window.location.href = url;
      } else {
        e.preventDefault();
        $(this).addClass('view-text');
      }
    }
  });

  // IE読み込み時のtransition対策
  $('body').removeClass('preload');
});

// Topに戻るボタン
const observeAddClass = function(entries) {
  for(let i = 0; i < entries.length; i++) {
    if(entries[i].isIntersecting) {
      document.querySelector('.go-top').classList.add('btn-static');
    } else {
      document.querySelector('.go-top').classList.remove('btn-static');
    }
  }
}
const footerEle = document.querySelector('.footer');
const goTopBtnHandle = new IntersectionObserver(observeAddClass);
goTopBtnHandle.observe(footerEle);

/* eslint-disable */
if(document.getElementsByClassName('wave-wrapper').length) {
  // 波の動き
  wavify( document.querySelector('.wave-top'), {
    height: 60,
    bones: 3,
    amplitude: 40,
    color: '#ffc400',
    speed: .25
  });
  wavify( document.querySelector('.wave-bottom'), {
    height: 60,
    bones: 3,
    amplitude: 40,
    color: '#ffc400',
    speed: .25
  });
  wavify( document.querySelector('.wave-top02'), {
    height: 60,
    bones: 3,
    amplitude: 40,
    color: '#ffc400',
    speed: .25
  });
  wavify( document.querySelector('.wave-bottom02'), {
    height: 60,
    bones: 3,
    amplitude: 40,
    color: '#ffc400',
    speed: .25
  });
}
/* eslint-disable */
