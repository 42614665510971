$(function() {
  // トップMVスライダー
  $('.js-top-slides').slick({
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    centerMode: true,
    dots: true,
    dotsClass: 'c-dots',
    prevArrow: '.top-mv-prev',
    nextArrow: '.top-mv-next',
    variableWidth: false,
    centerPadding: `28%`,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          centerPadding: '0'
        }
      }
    ]
  });

  // 商品詳細スライダー
  const $itemSlides = $('.js-item-slides');
  const $thumbnail = $('.js-item-thumbnail');
  $thumbnail.each(function(index) {
    $(this).attr('data-index', index);
  });
  $itemSlides.on('init', function() {
    $('.js-item-slide.slick-slide.slick-current').attr('data-slick-index');
    $('.js-item-thumbnail[data-index=0]').addClass('thumbnail-current');
  });
  $itemSlides.slick({
    slidesToShow: 1,
    fade: true,
    arrows: false,
    infinite: false
  });
  $itemSlides.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
    $thumbnail.each(function() {
      $(this).removeClass('thumbnail-current');
      $(`.js-item-thumbnail[data-index="${nextSlide}"]`).addClass('thumbnail-current');
    });
  });
  $thumbnail.on('click', function() {
    const index = $(this).attr('data-index');
    $itemSlides.slick('slickGoTo', index, false);
  });
});
